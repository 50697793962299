/* global clearInterval, console, setInterval */
import { getAccessToken, splitAfterNumber, splitTablename, parseDateToISO, convertToMinutes, handleAPIRequest, parseArrayObjectToRow } from './functionUtils.js';

// Common error message
const NO_DATA_ERROR_MSG = "No data found in this table for the specified parameters."

// Global variables for batching pattern
let _batch = [];
let _isBatchedRequestScheduled = false;

/**
 * Time weighted average
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} timeInterval string end date of the required data.
 * @param {string} step string end date of the required data.
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
 */
export async function TimeWeightedAvg(url, region, tableName, tagNames, startDate, endDate, timeInterval, step, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation) { 

  try {

    // Handle optional params - excel will pass null if not provided rather than specified default
    if(includeBadData == null){
      includeBadData = "true"
    } 

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/events/timeweightedaverage"

    // Parse time interval and create params object
    const {number: timeIntervalRate, unit: timeIntervalUnit} = splitAfterNumber(timeInterval)

    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "time_interval_rate": timeIntervalRate,
      "time_interval_unit": timeIntervalUnit,
      "window_length": 1,
      "window_size_mins": convertToMinutes(timeIntervalRate, timeIntervalUnit),
      "step": step,
      "pivot": true,
      "include_bad_data": includeBadData
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn === "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]]

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }
  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Raw data
 * @customfunction
 * @streaming
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object. 
 */
export async function Raw(url, region, tableName, tagNames, startDate, endDate, includeBadData = "true", refreshIntervalSeconds = null, invocation){

  try {

    // Handle optional params - excel will pass null if not provided rather than specified default
    if(includeBadData == null){
      includeBadData = "true"
    } 

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/events/raw"
    
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "include_bad_data": includeBadData
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = ["EventTime", "TagName", "Status", "Value"]

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }
  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Resample data
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} timeInterval string end date of the required data.
 * @param {string} aggMethod string for aggregation method (options: first, last, avg, min, max)
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
 */
export async function Resample(url, region, tableName, tagNames, startDate, endDate, timeInterval, aggMethod, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation){

  try {

    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

     // append correct api route to url
    const relativeUrl = "/events/resample"

    // Parse time interval and create params object
    const {number: timeIntervalRate, unit: timeIntervalUnit} = splitAfterNumber(timeInterval)
      
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "time_interval_rate": timeIntervalRate,
      "time_interval_unit": timeIntervalUnit,
      "sample_rate": timeIntervalRate,
      "sample_unit": timeIntervalUnit,
      "agg_method": aggMethod,
      "pivot": true,
      "include_bad_data": includeBadData
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn == "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]]

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }

  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Interpolate data
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} timeInterval string end date of the required data.
 * @param {string} aggMethod string for aggregation method (options: first, last, avg, min, max)
 * @param {string} interpolationMethod string for interpolation method
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
 */
export async function Interpolate(url, region, tableName, tagNames, startDate, endDate, timeInterval, aggMethod, interpolationMethod, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation){

  try {

    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }
    

    // append correct api route to url
    const relativeUrl = "/events/interpolate"

    // Parse time interval and create params object
    const {number: timeIntervalRate, unit: timeIntervalUnit} = splitAfterNumber(timeInterval)
      
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
    
    const params = {
    ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "time_interval_rate": timeIntervalRate,
      "time_interval_unit": timeIntervalUnit,
      "sample_rate": timeIntervalRate,
      "sample_unit": timeIntervalUnit,
      "agg_method": aggMethod,
      "include_bad_data": includeBadData,
      "interpolation_method": interpolationMethod,
      "pivot": true
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn == "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]]
    
    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }
  
  }
  catch (error) {
    handleError(error, invocation)
  }
}

/**
 * Interpolate data at specifoc times
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string[][]} timestamps the range of cells of the timestamps
 * @param {string} windowLength string databricks table name.
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
*/
export async function InterpolationAtTime(url, region, tableName, tagNames, timestamps, windowLength, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation){

  try {

    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // flatten timestamps array to account from excel source in format [[a], [b], ...] and form-based [a, b, ...]
    timestamps = timestamps.flat(1);

    // append correct api route to url
    const relativeUrl = "/events/interpolationattime"
      
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "include_bad_data": includeBadData,
      "timestamps": timestamps,
      "window_length": windowLength,
      "pivot": true
    };
  
    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn.toLowerCase() == "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]] 

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }

  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Circular average data
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} timeInterval string end date of the required data.
 * @param {string} lowerBound lower bound for the averaging procedure
 * @param {string} upperBound upper bound for the averaging procedure
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
 */
export async function CircularAvg(url, region, tableName, tagNames, startDate, endDate, timeInterval, lowerBound, upperBound, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation){
  
  try {
    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/events/circularaverage"

    // Parse time interval and create params object
    const {number: timeIntervalRate, unit: timeIntervalUnit} = splitAfterNumber(timeInterval)
      
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "time_interval_rate": timeIntervalRate,
      "time_interval_unit": timeIntervalUnit,
      "sample_rate": timeIntervalRate,
      "sample_unit": timeIntervalUnit,
      "include_bad_data": includeBadData,
      "pivot": true,
      "lower_bound": lowerBound,
      "upper_bound": upperBound
    };
  
    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn.toLowerCase() == "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]] 
    
    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }
  }
  catch (error) {
    handleError(error, invocation)
  }
}

/**
 * Circular Standard Deviation of data
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} timeInterval string end date of the required data.
 * @param {string} lowerBound lower bound for the standard deviation procedure
 * @param {string} upperBound upper bound for the standard deviation procedure
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {string} includeDateColumn boolean on whether to include the date column.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]>} invocation Invocation object.
 */
export async function CircularStdev(url, region, tableName, tagNames, startDate, endDate, timeInterval, lowerBound, upperBound, includeBadData="true", includeDateColumn="true", refreshIntervalSeconds=null, invocation){

  try {
    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(includeDateColumn == null){
      includeDateColumn = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/events/circularstandarddeviation"
  
    // Parse time interval and create params object
    const {number: timeIntervalRate, unit: timeIntervalUnit} = splitAfterNumber(timeInterval)
      
      // Allow for tablename optionality
      let tablenameParams = {}
      if(tableName != "" && tableName != null){
        tablenameParams = splitTablename(tableName)
      }
        
      const params = {
        ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "time_interval_rate": timeIntervalRate,
      "time_interval_unit": timeIntervalUnit,
      "sample_rate": timeIntervalRate,
      "sample_unit": timeIntervalUnit,
      "include_bad_data": includeBadData,
      "pivot": true,
      "lower_bound": lowerBound,
      "upper_bound": upperBound
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = includeDateColumn.toLowerCase() == "true" ? ["EventTime", ...tagNames[0]] : [...tagNames[0]] 
  
    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }

  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Metadata for specified tags
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @returns {string[][]} A dynamic array with columns for datetime and values.
 */
export async function Metadata(url, region, tableName, tagNames){

  try {

      // append correct api route to url
      const relativeUrl = "/events/metadata"
        
      const params = {
        ...splitTablename(tableName),
        "region": region
      };

      delete params["data_type"]
  
      const payload = {
        "tag_name": tagNames[0]
      };
  
      // Make request by pushing to the batch
      const data = await _pushOperation({"url": url, "relativeUrl": relativeUrl, "params": params, "payload": payload});

      // Parse data
      const keysToInclude = ["TagId", "TagName", "Description",	"UoM",	"PointSource", "msrc_id"]
      const locale = Intl.DateTimeFormat().resolvedOptions().locale; // Get user locale for date formatting
      const dateSetting = localStorage.getItem("dateSettings") || "user-timezone"
      const parsedData = parseArrayObjectToRow(data, keysToInclude, locale, dateSetting)
      if(parsedData.length == 0){
        throw new Error(NO_DATA_ERROR_MSG)
      }
      return parsedData
  }
  catch (error) {
    return handleError(error)
  }
}

/**
 * Latest data for specified tags
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} limit number of rows to be returned
 * @param {string} offset number of rows to offset by.
 * @param {string} valueOnly boolean for whether to only return the.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]} invocation Invocation object.
 */
export async function Latest(url, region, tableName, tagNames, limit, offset, valueOnly="false", refreshIntervalSeconds=null, invocation){

  try {
    // Handle optional params - excel will pass null if not
    if(valueOnly == null){
      valueOnly = "false"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl =  "/events/latest"
      
    const params = {
      ...splitTablename(tableName),
      "region": region,
      "limit": limit,
      "offset": offset
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = valueOnly.toLowerCase() == "true" ? ["Value"] : ["EventTime", "TagName", "Time",	"Status",	"Value"]

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }

  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Summary data for specified tags
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} region string region of which the data resides
 * @param {string} tableName string databricks table name.
 * @param {string[][]} tagNames string name of the tag.
 * @param {string} startDate string start date of the required data.
 * @param {string} endDate string end date of the required data.
 * @param {string} summaryType string type of summary i.e. Avg, Min, Max, Sum, Count
 * @param {string} includeBadData boolean on whether to include data with status Bad.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]} invocation Invocation object.
 */
export async function Summary(url, region, tableName, tagNames, startDate, endDate, summaryType, includeBadData="true", refreshIntervalSeconds=null, invocation){

  try {

    // Handle optional params - excel will pass null if not
    if(includeBadData == null){
      includeBadData = "true"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/events/summary"
      
    // Allow for tablename optionality
    let tablenameParams = {}
    if(tableName != "" && tableName != null){
      tablenameParams = splitTablename(tableName)
    }
      
    const params = {
      ...tablenameParams,
      "region": region,
      "start_date": startDate,
      "end_date": endDate,
      "include_bad_data": includeBadData,
      "limit": 1,
    };

    const payload = {
      "tag_name": tagNames[0]
    };

    const keysToInclude = [summaryType]
  
    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }

  }
  catch (error) {
    handleError(error, invocation)
  }
}


/**
 * Custom data
 * @customfunction
 * @param {string} url string url of the api
 * @param {string} sqlQuery string that represents the sql query
 * @param {string} limit Represents the max number of rows returned from the SQL query
 * @param {string} specifiedWarehouse for whether a specified warehouse is used.
 * @param {number} refreshIntervalSeconds number of seconds to refresh the data.
 * @param {CustomFunctions.StreamingInvocation<any[][]} invocation Invocation object.
 */
export async function SQL(url, region, sqlQuery, limit, specifiedWarehouse="false", refreshIntervalSeconds=null, invocation){

  try {

    // Handle optional params - excel will pass null if not
    if(specifiedWarehouse == null){
      specifiedWarehouse = "false"
    }

    if(refreshIntervalSeconds != null){
      // convert to number and check if it is a valid number
      refreshIntervalSeconds = Number(refreshIntervalSeconds)

      if(isNaN(refreshIntervalSeconds) || !isFinite(refreshIntervalSeconds)){
        throw new Error("Refresh interval must be a finite number.")
      } else if(refreshIntervalSeconds < 10){
        throw new Error("Refresh interval must be at least 10 seconds.")
      }
    }

    // append correct api route to url
    const relativeUrl = "/sql/execute"


    let params = {
      "limit": parseInt(limit, 10),
      "region": region
    };


    if(String(specifiedWarehouse) == "true"){
      params["x-databricks-server-hostname"] = localStorage.getItem("hostname")
      params["x-databricks-server-http-path"] = localStorage.getItem("httpPath")
    }

    const payload = {
      "sql_statement": sqlQuery + " "
    };

    const keysToInclude = []

    // Call once before setting up the interval
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)

    // If refresh interval is provided, set up a timer to refresh the data
    if(refreshIntervalSeconds != null){
      setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation)
    }
  }
  catch (error) {
    handleError(error, invocation)
  }
}


function _pushOperation(args) {
  // Create an entry for your custom function.
  const invocationEntry = {
    args: args,
    resolve: undefined,
    reject: undefined,
  };

  // Create a unique promise for this invocation,
  // and save its resolve and reject functions into the invocation entry.
  const promise = new Promise((resolve, reject) => {
    invocationEntry.resolve = resolve;
    invocationEntry.reject = reject;
  });

  // Push the invocation entry into the next batch.
  _batch.push(invocationEntry);

  // If a remote request hasn't been scheduled yet,
  // schedule it after a certain timeout, e.g., 100 ms.
  if (!_isBatchedRequestScheduled) {
    _isBatchedRequestScheduled = true;
    setTimeout(_makeRemoteRequest, 100);
  }

  // Return the promise for this invocation.
  return promise;
}


// This function makes a request for remote processing of the whole batch,
// and matches the response batch to the request batch.
async function _makeRemoteRequest() {
  // Copy the shared batch and allow the building of a new batch while you are waiting for a response.
  // Note the use of "splice" rather than "slice", which will modify the original _batch array
  // to empty it out.
  const batchCopy = _batch.splice(0, _batch.length);
  try{
  _isBatchedRequestScheduled = false;

  // Get url from storage, otherwise default to the first request url
  const baseUrl = localStorage.getItem('apiUrl') || batchCopy[0].args["url"]
  const url = baseUrl + "/events/batch"

  // Get access token
  const access_token = await getAccessToken()
  
  // Get overall params from the first request
  const params = {
    "region": batchCopy[0].args["params"]["region"]
  };


  // Build request batch in required format
  const payload = {"requests": []};
  batchCopy.forEach((item) => {
    payload["requests"].push({
      "url": item.args["relativeUrl"],
      "method": "POST",
      "params":  item.args["params"],
      "body": item.args["payload"]
    });
  })

  // Make the remote request.
  handleAPIRequest(url, access_token, params, payload) 
    .then((responseBatch) => {
      // Match each value from the response batch to its corresponding invocation entry from the request batch,
      // and resolve the invocation promise with its corresponding response value.
      responseBatch["data"].forEach((response, index) => {
        if (response.error) {
          batchCopy[index].reject(new Error(response.error));
        } else {
          batchCopy[index].resolve(response);
        }
      });
    });
  } catch (error) {
    console.error(error)
    // Reject all promises in the batch with the same error.
    batchCopy.forEach((el, index) => {
        el.reject(error);
    });
  }
}

// Error handling function
function handleError(error, invocation=null){
  let error_text = String(error)
  if(error_text.includes("access token")){
    error_text += "Try refresh via the dashboard/with CTRL+ALT+F9 (windows)."
  }
  if(invocation){
    invocation.setResult([[error_text]]);
  } else{
    return [[error_text]]
  }
}

// Utility function to push to batch and parse data
async function getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation){

    // if start date and end date in params, then convert to ISO
    if(params["start_date"]){
      params["start_date"] = parseDateToISO(params["start_date"])
    }

    if(params["end_date"]){
      params["end_date"] = parseDateToISO(params["end_date"])
    }

    // if timetimestamps in params, then convert to ISO
    if(params["timestamps"]){
      params["timestamps"] = params["timestamps"].map( (timestamp) => parseDateToISO(timestamp))
    }

    // Make request by pushing to the batch
    const data = await _pushOperation({"url": url, "relativeUrl": relativeUrl, "params": params, "payload": payload});

    // Get keys to include from data
    if(keysToInclude.length == 0){
      const dataSchema = data.schema.fields.map( (field) => field.name)
      keysToInclude = dataSchema
    }

    // Get date locale and settings
    const locale = Intl.DateTimeFormat().resolvedOptions().locale; // Get user locale for date formatting
    const dateSetting = localStorage.getItem("dateSettings") || "user-timezone"

    // parse data
    const parsedData = parseArrayObjectToRow(data, keysToInclude, locale, dateSetting)

    if(parsedData.length == 0){
      throw new Error(NO_DATA_ERROR_MSG)
    }
    invocation.setResult(parsedData)
}

// Utility function to set up interval
function setUpInterval(url, relativeUrl, params, payload, keysToInclude, refreshIntervalSeconds, invocation){
  // Convert refresh interval to milliseconds
  const intervalMilliseconds = refreshIntervalSeconds * 1000

  const intervalId = setInterval(async () => {
    await getDataAndSetResult(url, relativeUrl, params, payload, keysToInclude, invocation)
  }, intervalMilliseconds);

  invocation.onCanceled = () => {
    clearInterval(intervalId);
  }
}
CustomFunctions.associate("TIMEWEIGHTEDAVG", TimeWeightedAvg);
CustomFunctions.associate("RAW", Raw);
CustomFunctions.associate("RESAMPLE", Resample);
CustomFunctions.associate("INTERPOLATE", Interpolate);
CustomFunctions.associate("INTERPOLATIONATTIME", InterpolationAtTime);
CustomFunctions.associate("CIRCULARAVG", CircularAvg);
CustomFunctions.associate("CIRCULARSTDEV", CircularStdev);
CustomFunctions.associate("METADATA", Metadata);
CustomFunctions.associate("LATEST", Latest);
CustomFunctions.associate("SUMMARY", Summary);
CustomFunctions.associate("SQL", SQL);