// Utils
import axios from 'axios'
import qs from 'qs'
import moment from 'moment';

const dateFormatMappings = {
  default: 'YYYY-MM-DD HH:mm:ss', // ISO-like format as default
  'en-GB': 'DD/MM/YYYY HH:mm:ss', // UK format
  'en-US': 'MM-DD-YYYY HH:mm:ss', // US format
};

export function finalAPIDateFormat(momentInputDate) {
// Convert the moment object to the final API date format, rounding to the minute above and without milliseconds in UTC
  const dt =  momentInputDate.toISOString().split('.')[0] + 'Z';
  return dt
}

export function parseDateToISO(inputDate) {

      // Define an array of accepted format string
      const formats = [
        moment.ISO_8601,
        "DD/MM/YYYY HH:mm:ss",
        "DD/MM/YYYY HH:mm",
        "DD/MM/YYYY",
        "MM-DD-YYYY HH:mm:ss",
        "MM-DD-YYYY HH:mm",
        "MM-DD-YYYY"
    ];
      // Iterate over the formats and return the first that successfully parses the inputDate
      for (const format of formats) {
          if (moment(inputDate, format, true).isValid()) {
            const momentDate = moment(inputDate, format);
              return finalAPIDateFormat(momentDate)
          }
      }
    
    // Handle Excel serial date format
    if (!isNaN(parseFloat(inputDate)) && isFinite(inputDate)) {
        const secondsInADay = 60*60*24;
        const days = Math.floor(inputDate);
        const seconds = Math.floor((inputDate - days) * secondsInADay);
        const milliseconds = Math.ceil( (inputDate - days - (seconds/secondsInADay) ) * secondsInADay * 1000)
        const momentDate = moment('1899-12-30').add(days, 'days').add(seconds, 'seconds').add(milliseconds, "milliseconds"); // Excel incorrectly assumes 1900 was a leap year
        return finalAPIDateFormat(momentDate)
    }

    // Otherwise, it may be the PI syntax for *, *-1h, *-1y etc
    if (inputDate && inputDate.length > 0 && inputDate.startsWith('*')) {
        return parsePIDateString(inputDate);
    }
    
    // Return an error or null if the format is not recognized
    throw new Error("Date format not recognized");
}


function parsePIDateString(input) {
  // Parses the PI syntax for time intervals e.g. *-1h, *-1y. Only allows integers for the time value.

  // Check if the input is "*" which means now.
  if (input === '*') {
    const momentDate = moment();
    return finalAPIDateFormat(momentDate)
  }

  // Extract the unit and the value from the input string.
  const match = input.match(/\*-(\d+)(s|m|h|d|w|y)/);
  if (!match) {
      throw new Error('Date format not recognized');
  }

  const [, value, unit] = match;
  const amount = parseInt(value, 10);

  // Calculate the date based on the unit and subtract the value from today's date.
  let date;
  switch (unit) {
      case 's': // seconds
        date = moment().subtract(amount, 'seconds');
        break;
      case 'm': // months
        date = moment().subtract(amount, 'minutes');
        break;
      case 'h': // hours
        date = moment().subtract(amount, 'hours');
        break;
      case 'd': // days
        date = moment().subtract(amount, 'days');
        break;
      case 'w': // weeks
        date = moment().subtract(amount, 'weeks');
        break;
      case 'y': // years
        date = moment().subtract(amount, 'years');
        break;
      default:
        throw new Error('Invalid time unit.');
  }

  return finalAPIDateFormat(date)
}

export function convertDateFormat(inputDate, locale, dateSetting) {

    // From 'YYYY-MM-DDTHH:MM:SSZ' to user locale format);
    if(moment(inputDate, moment.ISO_8601, true).isValid() && inputDate.length > 0){
      const format = dateFormatMappings[locale] || dateFormatMappings.default;

      // convert the date according to the date setitng
      if(dateSetting === 'databricks-table'){
        return moment.parseZone(inputDate).format(format);
      } else if(dateSetting === 'utc'){
        return moment(inputDate).utc().format(format);
      } else {
        // default to user-timezone
        return moment(inputDate).format(format);
      }
    } else {
      throw new Error("Date format in result not recognized");
    }
}

export function splitAfterNumber(inputStr) {
    // Use regex to match the number and unit separately
    // \s* allows for zero or more spaces between the number and unit
    const match = inputStr.match(/^(\d+)\s*(\D+)$/);

    if (match) {
        return {
            number: match[1], 
            unit: convertString(match[2].trim()) // trim to remove any leading/trailing spaces
        };
    }

    throw new Error('Invalid time interval format');
}
  
  
export function convertString(input) {
    // convert the shorthand time intervals to full words
    switch(input) {
        case 's':
            return 'second';
        case 'm':
            return 'minute';
        case 'h':
              return 'hour';
        case 'd':
            return 'day';
        default:
            return input;
    }
  }

  export function convertToMinutes(number, unit) {
    // Convert the time interval to minutes
    switch(unit) {
        case 'second':
            return number / 60;
        case 'minute':
            return number;
        case 'hour':
            return number * 60;
        case 'day':
            return number * 60 * 24;
        default:
            throw new Error('Invalid time interval unit');
    }
  }
  
  
  
export const handleAPIRequest = async (url, token, params, payload) => {
  try {
      const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
      };

      const response = await axios.post(url, payload, {
          headers: headers,
          params: params, 
          paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' });
          }
      });

      return response.data;
  } catch (error) {
      if(error.response && error.response.data.detail){
        throw new Error(`Failed to fetch data - ${error.response.data.detail}`);
      } else{
        throw new Error(`Failed to fetch data - ${error.message}`);
      }
  }
};


export const handleAPIRequestPromise = async (url, token, params, payload) => {

    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    };

    return axios.post(url, payload, {
        headers: headers,
        params: params, 
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
    .then(response => response.data)
    .catch(error => {
      if(error.response && error.response.data.detail){
        throw new Error(`Failed to fetch data - ${error.response.data.detail}`);
      } else{
        throw new Error(`Failed to fetch data - ${error.message}`);
      }
    })
};
  
export const parseArrayObjectToRow = (arrayObj, keysToInclude, locale, dateSetting="user-timezone") => {
  
        // specify the order of keys
        const order = arrayObj["schema"]["fields"].reduce( (acc, obj) => {
          if(obj["name"] !== "index"){
            acc.push(obj["name"])
          }
          return acc
        }, []) 
  
        const arrayData = arrayObj["data"].map(obj =>
          order.reduce( (acc, key) => {
            if(keysToInclude.includes(key)){
              if(key == "EventTime"){
                acc.push(convertDateFormat(obj[key], locale, dateSetting))
              } else{
                acc.push(obj[key])
              }
            }
            
            return acc
          }, [])
        );
  
        return arrayData
  }
  
export const splitTablename = (str) => {
    // example - rtdip.sensors.pernis_restricted_events_float
    
    try {
          // Retireve parts by splitting string
      const parts = str.split('.');
      const businessUnit = parts[0];
      const assetSecurityType = parts[2].split('_');
      
      // Get the asset, data security level and type
      const asset = assetSecurityType[0].toLowerCase();
      const dataSecurityLevel = assetSecurityType[1].toLowerCase();
      const dataType = assetSecurityType[assetSecurityType.length - 1].toLowerCase();
    
      // Return the formatted object
      return {
        business_unit: businessUnit,
        asset: asset,
        data_security_level: dataSecurityLevel,
        data_type: dataType
      };
    } catch (error){
      throw new Error(`Unsupported table name format supplied. Please use the format 'businessunit.catalog.asset_datasecuritylevel_datatype'`);
    }

  }



  export const getTablesAndCatalogs = async (apiUrl, parameters) => {

    // Get access token
    const access_token = await getAccessTokenPromise()
    
    // initialise parameter to determine which item to be extracted from result
    let requiredParam = ""
    let sqlQuery = ""

    // Create query based on provided params
    if (parameters["catalogName"] && parameters["schemaName"]){
        // if have catalog and schema get tables
        sqlQuery = `select table_name as \`tableName\` from \`system\`.information_schema.tables where table_catalog = "${parameters["catalogName"]}" and table_schema = "${parameters["schemaName"]}" order by table_schema, table_name`
        requiredParam = "tableName"
    }
    else if (parameters["catalogName"]){
      //  If have only catalog get schemas
      sqlQuery = `select schema_name as \`databaseName\` from \`system\`.information_schema.schemata where catalog_name = "${parameters["catalogName"]}" order by schema_name`
      requiredParam = "databaseName"
    } else {
        //  Else just get catalogs
        sqlQuery = `select catalog_name as \`catalog\` from system.information_schema.catalogs order by catalog_name`
        requiredParam = "catalog"
    }

    if(parameters["metadataQuery"]){
      sqlQuery = `select table_name as \`tableName\` from \`system\`.information_schema.tables where table_catalog = "${parameters["catalogName"]}" and table_schema = "${parameters["schemaName"]}" and table_name like '%metadata%' order by table_schema, table_name`
      requiredParam = "tableName"
    }

    const params = {
      "region": parameters["region"]
    }


    const payload = {
      "sql_statement": sqlQuery
    };
  
    return handleAPIRequestPromise(`${apiUrl}/sql/execute`, access_token, params, payload)
    .then(response => {
      const data = []
      for(const row of response.data){
        data.push(row[requiredParam])
      }
      return data
    })
    .catch(error => {
        console.error('Request failed', error);
        return []
    });
    
  }


export const searchTagNames = async (apiUrl, parameters) => {

 // Get access token
 const access_token = await getAccessTokenPromise()

 const catalog = parameters["catalog"]
 const search_query = parameters["search_query"]
 const page_number = parameters["page_number"]
 const tablename = parameters["tablename"]

 const apiParams = {
  "region": parameters["region"]
 }

 let tables = []
 if(tablename){
  tables = [tablename]
 } else {
    // Get table names for catalog
    const tableQuery = `SELECT table_name, table_schema, table_catalog
    FROM ${catalog}.information_schema.tables
    WHERE table_schema = "sensors"
    AND table_name LIKE '%_metadata%'
  `
  const tableApiPayload = {"sql_statement": tableQuery}
  const response = await handleAPIRequestPromise(`${apiUrl}/sql/execute`, access_token, apiParams, tableApiPayload)
  tables = response.data.map(row => `${row.table_catalog}.${row.table_schema}.${row.table_name}`)
 }
 
 // Constryct query from params
 const tagQuery = construct_sql_query(tables, search_query, page_number)
 const tagApiPayload = {"sql_statement": tagQuery}
 const tagResponse = await handleAPIRequestPromise(`${apiUrl}/sql/execute`, access_token, apiParams, tagApiPayload)
 const keys = ['TagName', 'Description', 'SourceTable'];
 const flattenedTagResults = tagResponse.data.map(obj => {
   return keys.map(key => obj[key] || null);
 });

 return flattenedTagResults

}


export const construct_sql_query = (tables, search_query, page, page_size=10) => {
 const words = search_query.replace(/\W+/g, ' ').toLowerCase().split(/\s+/);

 // Create a regex pattern as a string from the list of words
 const escapedWords = words.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
 const regex_pattern = escapedWords.join('|');

 // Constructing conditions for ranking
 // Assign a high score for an exact match in TagName
 const exact_match_condition = `IF(LOWER(TagName) = '${search_query.toLowerCase()}', 10, 0)`

 const word_conditions = words.map(word => 
   `IF(LOWER(TagName) LIKE '%${word}%', 1, 0)` +
   ` + IF(LOWER(Description) LIKE '%${word}%', 1, 0)`
 ).join(" + ");
 
 let conditions = exact_match_condition
 if(word_conditions.length > 0){
     conditions = conditions + " + " + word_conditions
 }

 const offset = (page - 1) * page_size
   
 // Construct the query for each table and union them
 const union_queries = []
 for(const table_name of tables){
     const query = `
         SELECT TagName, Description, '${table_name}' AS SourceTable, (${conditions}) AS relevance_score
         FROM ${table_name}
         WHERE LOWER(TagName) RLIKE '${regex_pattern}'
         OR LOWER(Description) RLIKE '${regex_pattern}'
     `
     union_queries.push(query)
 }
 const combined_query = union_queries.join(" UNION ")

 // Final query with pagination
 const sql_query = `
 SELECT TagName, Description, SourceTable, relevance_score
 FROM (${combined_query}) AS combined
 ORDER BY relevance_score DESC
 LIMIT ${page_size} OFFSET ${offset}
 `

 return sql_query
}

// Manual in-memory cache for the access token since there is a bug with getAccessToken
// https://github.com/OfficeDev/office-js/issues/3298

// Function to parse JWT token - not required to verify as this is done server side
function parseJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function getCachedAccessToken() {
  const accessTokenCache = JSON.parse(sessionStorage.getItem('accessTokenCache')) || {};
  if (accessTokenCache.value && accessTokenCache.expiry && accessTokenCache.expiry > Date.now()) {
    return accessTokenCache.value;
  }
  return null;
}

function cacheAccessToken(accessToken) {
  const accessTokenCache = {
    value: accessToken,
    expiry: parseJwt(accessToken).exp * 1000 // Convert to milliseconds
  }
  sessionStorage.setItem('accessTokenCache', JSON.stringify(accessTokenCache));
}


export const getAccessToken = async () => {

  // Retieve cached token immediately if it exists and is not expired
  let cachedToken = getCachedAccessToken();
  if (cachedToken) {
      return cachedToken;
  }

  // check if permitted to get access token, and try every 100ms until possible
  let tokenLock = sessionStorage.getItem('tokenLock') === 'true';

  while (tokenLock) {
      await new Promise(resolve => setTimeout(resolve, 100));
      tokenLock = sessionStorage.getItem('tokenLock') === 'true';
  }

  // after token lock is released, try to get the token from cache again
  cachedToken = getCachedAccessToken();
  if (cachedToken) {
      return cachedToken;
  }

  // otherwise, try to get the token from Office API
  try {
      // Set lock to prevent multiple requests for access token
      sessionStorage.setItem('tokenLock', 'true');

      // Fetch the access token
      const accessToken = await Office.auth.getAccessToken({ //NOSONAR
          allowSignInPrompt: true,
          allowConsentPrompt: true,
          forMSGraphAccess: true,
      });

      // cache the access token
      cacheAccessToken(accessToken);

      // Release the lock
      sessionStorage.setItem('tokenLock', 'false');

      return accessToken
  } catch (error) {
      // Release the lock
      sessionStorage.setItem('tokenLock', 'false');
      throw new Error(`Failed to fetch access token - ${error.message}`);
  }
};

// Function to get access token via promise, as shared runtime does not support async/await (for use within taskpane)
export const getAccessTokenPromise = () => {

  // Retieve cached token if it is exists and is not expired
  const cachedToken = getCachedAccessToken();
  if (cachedToken) {
    return Promise.resolve(cachedToken);
  }

  return Office.auth.getAccessToken({
    allowSignInPrompt: true,
    allowConsentPrompt: true,
    forMSGraphAccess: true,
  }).then(accessToken => {
    // cache the access token
    cacheAccessToken(accessToken);
    return accessToken;
  }).catch(error => {
    throw new Error(`Failed to fetch access token - ${error.message}`);
  });
};
